/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
import { AxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';

import i18n from 'i18n';
import { submitSurveyService, trackingBehaviourUserService } from 'services/assignments';
import { setLocalStorage } from 'services/common/storage';
import { HeartRiskMatrixItem } from 'services/systems/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { saveTranslation } from 'store/systems';
import {
  SUBMIT_QUEUE, SUBMIT_QUEUE_FAIL, TRACKING_STEP_QUEUE
} from 'utils/constants';

export const pushToQueue = (data: any, localKey: string) => {
  const dataLocal = localStorage.getItem(localKey);
  const dataSubmitLocal = dataLocal ? JSON.parse(dataLocal) as any[] : [];
  localStorage.setItem(localKey, JSON.stringify([...dataSubmitLocal, data]));
};

export const matrixKidneyRisk = (question92: 'yes' | 'no' | 'unknow', question101: 'unknow' | 'no' | 'stage_3' | 'stage_4_5') => {
  if (question92 === 'yes') {
    switch (question101) {
      case 'stage_3':
        return 'proteinuriaAndCdk3';
      case 'stage_4_5':
        return 'proteinuriaAndCdk45';
      default:
        return 'proteinuria';
    }
  } else {
    switch (question101) {
      case 'stage_3':
        return 'cdk3';
      case 'stage_4_5':
        return 'cdk45';
      // case 'no':
      //   return 'none';
      default:
        return '';
    }
  }
};

export const scoredKidneyRisk = (data: any): string => {
  // Tính điểm nguy cơ thận
  if (data['question-000010-000001'] === 'unknow' || data['question-000010-000001'] === 'no') {
    let score = 0;
    if (data['question-000001'] === 'female') {
      score += 1;
    }
    if (Number(data['question-000002']) >= 50 && Number(data['question-000002']) <= 59) {
      score += 2;
    }
    if (Number(data['question-000002']) >= 60 && Number(data['question-000002']) <= 69) {
      score += 3;
    }
    if (Number(data['question-000002']) >= 70) {
      score += 4;
    }
    if (data['question-000004'] === 'yes') {
      score += 1;
    }
    if (data['question-000006'] === 'yes') {
      score += 1;
    }
    if (data['question-000008-000001'] === 'yes' || data['question-000008-000002'] === 'yes') {
      score += 1;
    }
    if (data['question-000009-000002'] === 'yes') {
      score += 1;
    }
    if (data['question-000013-000001'] === 'yes') {
      score += 1;
    }
    if (data['question-000013-000002'] === 'yes') {
      score += 1;
    }
    if (data['question-000013-000003'] === 'yes') {
      score += 1;
    }
    if (score >= 4) {
      return 'twentyPercent';
    }
    return 'zeroPercent';
  }
  // Mệnh đề cũ
  return matrixKidneyRisk(data['question-000009-000002'], data['question-000010-000001']);
};

const checkInRangeMinMax = (value: number, min: number | null, max: number | null) => {
  if (!min && !!max) {
    return value <= max;
  }
  if (!!min && !max) {
    return value >= min;
  }
  if (!!min && !!max) {
    return value >= min && value <= max;
  }
  return false;
};

export const matrixHeartRisk = (data: any, riskMatrix?: HeartRiskMatrixItem[]): { heartRiskCode: string | null, heartRiskScore: number | null } => {
  const clause1 = () => [
    data['question-000008-000001'],
    data['question-000008-000002'],
    data['question-000008-000003'],
    data['question-000008-000004'],
    data['question-000008-000005'],
    data['question-000009-000001'],
    data['question-000009-000002'],
    data['question-000009-000003']].some((value) => value === 'yes') || data['question-000010-000001'] === 'stage_4_5';
  const clause2 = () => data['question-000010-000001'] === 'stage_3';
  const clause3 = () => [data['question-000004'], data['question-000003'], data['question-000006'], data['question-000007']].every((value) => value === 'yes');
  const clause4 = () => data['question-000004'] === 'yes' && [data['question-000003'], data['question-000005'], data['question-000006'], data['question-000007']].some((value) => value === 'yes');
  const clause5 = () => data['question-000004'] === 'yes' && [data['question-000003'], data['question-000005'], data['question-000006'], data['question-000007']].some((value) => value === 'no' || value === 'unknow');
  const clause6 = () => [data['question-000011-000001'], data['question-000011-000002'], data['question-000012']].some((item) => item && item.value && item.value === 'unknow');
  const clause7 = () => data['question-000002'] < 40;

  switch (true) {
    case clause1():
      return {
        heartRiskCode: 'veryHigh',
        heartRiskScore: null,
      };
    case clause2():
      return {
        heartRiskCode: 'high',
        heartRiskScore: null
      };
    case clause3():
      return {
        heartRiskCode: 'veryHigh',
        heartRiskScore: null,
      };
    case clause4():
      return {
        heartRiskCode: 'high',
        heartRiskScore: null
      };
    case clause5():
      return {
        heartRiskCode: 'medium',
        heartRiskScore: null,
      };
    case clause6():
      return {
        heartRiskCode: 'missingHeartRisk',
        heartRiskScore: null,
      };
    case clause7():
      return {
        heartRiskCode: 'matrixAndLessAge',
        heartRiskScore: null
      };
    default:
      break;
  }

  if (!riskMatrix) {
    return {
      heartRiskCode: 'missingHeartRisk',
      heartRiskScore: null,
    };
  }

  const filterLv1 = riskMatrix.filter((item) => item.gender === data['question-000001']
    && checkInRangeMinMax(data['question-000002'], item.age.min, item.age.max)
    && item.smoking === data['question-000003']);
  const cholesterol = data['question-000011-000001'].value - data['question-000011-000002'].value;
  const bloodPressure = data['question-000012'];

  const isMg = data['question-000011-000001'].type === 'mg/dL';
  if (isMg) {
    if (bloodPressure.value < 100) {
      const result = filterLv1.filter((item) => checkInRangeMinMax(cholesterol, item.mgPerdL.min, item.mgPerdL.max) && checkInRangeMinMax(100, item.bloodPressure.min, item.bloodPressure.max));
      return result.length === 0 ? {
        heartRiskCode: 'missingHeartRisk',
        heartRiskScore: null,
      } : {
        heartRiskCode: result[0].risk.risk,
        heartRiskScore: result[0].risk.score
      };
    }

    if (bloodPressure.value > 179) {
      const result = filterLv1.filter((item) => checkInRangeMinMax(cholesterol, item.mgPerdL.min, item.mgPerdL.max) && checkInRangeMinMax(179, item.bloodPressure.min, item.bloodPressure.max));
      return result.length === 0 ? {
        heartRiskCode: 'missingHeartRisk',
        heartRiskScore: null,
      } : {
        heartRiskCode: result[0].risk.risk,
        heartRiskScore: result[0].risk.score
      };
    }

    const result = filterLv1.filter((item) => checkInRangeMinMax(cholesterol, item.mgPerdL.min, item.mgPerdL.max) && checkInRangeMinMax(bloodPressure.value, item.bloodPressure.min, item.bloodPressure.max));

    return result.length === 0 ? {
      heartRiskCode: 'missingHeartRisk',
      heartRiskScore: null,
    } : {
      heartRiskCode: result[0].risk.risk,
      heartRiskScore: result[0].risk.score
    };
  }

  if (bloodPressure.value < 100) {
    const result = filterLv1.filter((item) => checkInRangeMinMax(cholesterol, item.mmolPerL.min, item.mmolPerL.max) && checkInRangeMinMax(100, item.bloodPressure.min, item.bloodPressure.max));
    return result.length === 0 ? {
      heartRiskCode: 'missingHeartRisk',
      heartRiskScore: null,
    } : {
      heartRiskCode: result[0].risk.risk,
      heartRiskScore: result[0].risk.score
    };
  }

  if (bloodPressure.value > 179) {
    const result = filterLv1.filter((item) => checkInRangeMinMax(cholesterol, item.mmolPerL.min, item.mmolPerL.max) && checkInRangeMinMax(179, item.bloodPressure.min, item.bloodPressure.max));
    return result.length === 0 ? {
      heartRiskCode: 'missingHeartRisk',
      heartRiskScore: null,
    } : {
      heartRiskCode: result[0].risk.risk,
      heartRiskScore: result[0].risk.score
    };
  }
  const result = filterLv1.filter((item) => checkInRangeMinMax(cholesterol, item.mmolPerL.min, item.mmolPerL.max) && checkInRangeMinMax(bloodPressure.value, item.bloodPressure.min, item.bloodPressure.max));

  return result.length === 0 ? {
    heartRiskCode: 'missingHeartRisk',
    heartRiskScore: null,
  } : {
    heartRiskCode: result[0].risk.risk,
    heartRiskScore: result[0].risk.score
  };
};

const useOfflineSubmit = () => {
  const dispatch = useAppDispatch();
  const translations = useAppSelector((state) => state.systems.feTranslations);

  const [startTimeout, setStartTimeout] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [startTimeoutTracking, setStartTimeoutTracking] = useState(false);
  const intervalTrackingRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutTrackingRef = useRef<NodeJS.Timeout | null>(null);

  const stopInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const stopTrackingInterval = () => {
    if (intervalTrackingRef.current) {
      clearInterval(intervalTrackingRef.current);
      intervalTrackingRef.current = null;
    }
  };

  const { mutate: submitForm } = useMutation(
    'createBannerAction',
    async (data: any) => submitSurveyService(data),
    {
      onError: (error: AxiosError, variables) => {
        if (error.response?.status === 429) {
          stopInterval();
          setStartTimeout(true);
          return;
        }
        pushToQueue(variables, SUBMIT_QUEUE);
      }
    }
  );

  const { mutate: trackingBehaviourUser } = useMutation(
    'trackingBehaviourUser',
    async (data: any) => trackingBehaviourUserService(data),
    {
      onError: (error: AxiosError, variables) => {
        if (error.response?.status === 429) {
          stopTrackingInterval();
          setStartTimeoutTracking(true);
          return;
        }
        pushToQueue(variables, TRACKING_STEP_QUEUE);
      }
    }
  );

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      if (navigator.onLine) {
        const localData = localStorage.getItem(SUBMIT_QUEUE);
        const localDataFail = localStorage.getItem(SUBMIT_QUEUE_FAIL);
        const queues = localData ? JSON.parse(localData) as any[] : [];
        const queuesFail = localDataFail ? JSON.parse(localDataFail) as any[] : [];

        const queuesAll = [...queuesFail, ...queues];

        if (queuesAll.length > 0) {
          const firstItem = queuesAll.shift();
          setLocalStorage(SUBMIT_QUEUE, JSON.stringify(queuesAll));
          submitForm(firstItem);
        }
      }
    }, 1000 * 3);
  };

  const startTrackingInterval = () => {
    intervalTrackingRef.current = setInterval(() => {
      if (navigator.onLine) {
        const localData = localStorage.getItem(TRACKING_STEP_QUEUE);
        const queues = localData ? JSON.parse(localData) as any[] : [];
        if (queues.length > 0) {
          const firstItem = queues.shift();
          setLocalStorage(TRACKING_STEP_QUEUE, JSON.stringify(queues));
          trackingBehaviourUser(firstItem);
        }
      }
    }, 1000 * 3);
  };

  useEffect(() => {
    if (startTimeout) {
      timeoutRef.current = setTimeout(() => {
        setStartTimeout(false);
        startInterval();
      }, 60 * 1000); // 1-minute delay
    }
  }, [startTimeout]);

  useEffect(() => {
    if (startTimeoutTracking) {
      timeoutTrackingRef.current = setTimeout(() => {
        setStartTimeoutTracking(false);
        startTrackingInterval();
      }, 60 * 1000); // 1-minute delay
    }
  }, [startTimeoutTracking]);

  useEffect(() => {
    i18n.on('loaded', () => {
      const storeLanguages = {
        ...translations,
      };

      if (storeLanguages) {
        Object.keys(i18n.store.data).forEach((keyData) => {
          if (Object.values(i18n.store.data[keyData]?.translation).length > 0) {
            storeLanguages[keyData] = i18n.store.data[keyData];
          }
        });
      }
      dispatch(saveTranslation(storeLanguages));
    });

    startInterval();

    return () => {
      stopInterval();
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [dispatch, translations]);

  useEffect(() => {
    startTrackingInterval();

    return () => {
      stopTrackingInterval();
      if (timeoutTrackingRef.current) {
        clearTimeout(timeoutTrackingRef.current);
      }
    };
  }, []);
};

export default useOfflineSubmit;
